import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import HealthWellness from './pages/HealthWellness';
import TeamBuilding from './pages/TeamBuilding';
import Networking from './pages/Networking';
import Leisure from './pages/Leisure';
import AboutPage from './components/AboutPage';
import ContactForm from './components/ContactForm';
import Destinations from './components/Destinations';
import DominicaDestination from './pages/DominicaDestination';

function App() {
    return (
        <Router>
            <div className="flex flex-col min-h-screen bg-beige">
                <Header />
                <main className="flex-grow">
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/about" element={<AboutPage />} />
                        <Route path="/health-wellness" element={<HealthWellness />} />
                        <Route path="/team-building" element={<TeamBuilding />} />
                        <Route path="/networking" element={<Networking />} />
                        <Route path="/leisure" element={<Leisure />} />
                        <Route path="/destinations" element={<Destinations />} />
                        <Route path="/destinations/dominica" element={<DominicaDestination />} />
                        <Route path="/contact" element={<ContactForm />} />
                    </Routes>
                </main>
                <Footer />
            </div>
        </Router>
    );
}

export default App;