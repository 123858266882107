import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaImage, FaVideo, FaFolderOpen, FaArrowLeft } from 'react-icons/fa';

// Import images - these are large files, so we'll use relative paths
// All images are in the src/assets/Dominica Destination/ folder

// Function to get the correct path for assets
const getAssetPath = (filename) => {
  return require(`../assets/Dominica Destination/${filename}`);
};

// Organize content by categories
const content = {
  "Beaches": {
    type: "folder",
    items: [
      { type: "image", src: "Mero Beach (1).jpg", title: "Mero Beach View 1" },
      { type: "image", src: "Mero Beach (2).jpg", title: "Mero Beach View 2" },
      { type: "image", src: "Mero Beach (3).jpg", title: "Mero Beach View 3" },
      { type: "image", src: "Mero Beach (4).jpg", title: "Mero Beach View 4" },
      { type: "image", src: "Mero Beach (5).jpg", title: "Mero Beach View 5" },
      { type: "image", src: "Mero Beach (6).jpg", title: "Mero Beach View 6" },
      { type: "image", src: "Mero Beach (7).jpg", title: "Mero Beach View 7" },
      { type: "image", src: "Mero Beach (8).jpg", title: "Mero Beach View 8" },
      { type: "image", src: "Mero Beach (9).jpg", title: "Mero Beach View 9" },
    ]
  },
  "Waterfalls": {
    type: "folder",
    items: [
      { type: "image", src: "Victoria Falls (1).jpg", title: "Victoria Falls View 1" },
      { type: "image", src: "Victoria Falls (2).jpg", title: "Victoria Falls View 2" },
      { type: "image", src: "Victoria Falls (3).jpg", title: "Victoria Falls View 3" },
      { type: "image", src: "Victoria Falls (4).jpg", title: "Victoria Falls View 4" },
      { type: "image", src: "Victoria Falls (5).jpg", title: "Victoria Falls View 5" },
      { type: "image", src: "Victoria Falls (6).jpg", title: "Victoria Falls View 6" },
      { type: "image", src: "Victoria Falls (7).jpg", title: "Victoria Falls View 7" },
      { type: "image", src: "Jacko Falls (1).jpg", title: "Jacko Falls View 1" },
      { type: "image", src: "Jacko Falls (2).jpg", title: "Jacko Falls View 2" },
      { type: "image", src: "Jacko Falls (3).jpg", title: "Jacko Falls View 3" },
      { type: "image", src: "Jacko Falls (4).jpg", title: "Jacko Falls View 4" },
    ]
  },
  "Resorts": {
    type: "folder",
    items: [
      { type: "image", src: "Fort Young Hotel (1).jpg", title: "Fort Young Hotel View 1" },
      { type: "image", src: "Fort Young Hotel (2).jpg", title: "Fort Young Hotel View 2" },
      { type: "image", src: "Fort Young Hotel (3).jpg", title: "Fort Young Hotel View 3" },
      { type: "image", src: "Fort Young Hotel (4).jpg", title: "Fort Young Hotel View 4" },
      { type: "image", src: "Fort Young Hotel (5).jpg", title: "Fort Young Hotel View 5" },
      { type: "image", src: "Kempinski Resort (1).jpg", title: "Kempinski Resort View 1" },
      { type: "image", src: "Kempinski Resort (2).jpg", title: "Kempinski Resort View 2" },
      { type: "image", src: "Kempinski Resort (3).jpg", title: "Kempinski Resort View 3" },
      { type: "image", src: "Kempinski Resort (4).jpg", title: "Kempinski Resort View 4" },
      { type: "image", src: "Kempinski Resort (5).jpg", title: "Kempinski Resort View 5" },
      { type: "image", src: "Rosalie Bay Resort (1).jpg", title: "Rosalie Bay Resort View 1" },
      { type: "image", src: "Rosalie Bay Resort (2).jpg", title: "Rosalie Bay Resort View 2" },
      { type: "image", src: "Rosalie Bay Resort (3).jpg", title: "Rosalie Bay Resort View 3" },
      { type: "image", src: "Rosalie Bay Resort (4).jpg", title: "Rosalie Bay Resort View 4" },
      { type: "image", src: "Rosalie Bay Resort (5).jpg", title: "Rosalie Bay Resort View 5" },
      { type: "image", src: "Rosalie Bay Resort (6).jpg", title: "Rosalie Bay Resort View 6" },
      { type: "image", src: "The Champs (1).jpg", title: "The Champs View 1" },
      { type: "image", src: "The Champs (2).jpg", title: "The Champs View 2" },
      { type: "image", src: "The Champs (3).jpg", title: "The Champs View 3" },
    ]
  },
  "Scenic Spots": {
    type: "folder",
    items: [
      { type: "image", src: "Red Rocks (1).jpg", title: "Red Rocks View 1" },
      { type: "image", src: "Red Rocks (2).jpg", title: "Red Rocks View 2" },
      { type: "image", src: "Red Rocks (3).jpg", title: "Red Rocks View 3" },
      { type: "image", src: "Red Rocks (4).jpg", title: "Red Rocks View 4" },
      { type: "image", src: "Red Rocks (5).jpg", title: "Red Rocks View 5" },
      { type: "image", src: "Red Rocks (6).jpg", title: "Red Rocks View 6" },
      { type: "image", src: "Scotts Head (1).jpg", title: "Scotts Head View 1" },
      { type: "image", src: "Scotts Head (2).jpg", title: "Scotts Head View 2" },
      { type: "image", src: "Scotts Head (3).jpg", title: "Scotts Head View 3" },
      { type: "image", src: "Scotts Head (4).jpg", title: "Scotts Head View 4" },
      { type: "image", src: "Scotts Head (5).jpg", title: "Scotts Head View 5" },
      { type: "image", src: "Scotts Head (6).jpg", title: "Scotts Head View 6" },
      { type: "image", src: "Scotts Head Look out (1).jpg", title: "Scotts Head Lookout View 1" },
      { type: "image", src: "Scotts Head Look out (2).jpg", title: "Scotts Head Lookout View 2" },
      { type: "image", src: "Scotts Head Look out (3).jpg", title: "Scotts Head Lookout View 3" },
      { type: "image", src: "Scotts Head Look out (4).jpg", title: "Scotts Head Lookout View 4" },
      { type: "image", src: "Soufriere Scotts Head Kayaks (1).jpg", title: "Soufriere Scotts Head Kayaks View 1" },
      { type: "image", src: "Soufriere Scotts Head Kayaks (2).jpg", title: "Soufriere Scotts Head Kayaks View 2" },
      { type: "image", src: "Soufriere Scotts Head Kayaks (3).jpg", title: "Soufriere Scotts Head Kayaks View 3" },
      { type: "image", src: "_DAG5709-Pano.jpg", title: "Panoramic View" },
      { type: "image", src: "_DAG6061.jpg", title: "Landscape View 1" },
      { type: "image", src: "_DAG6076.jpg", title: "Landscape View 2" },
      { type: "image", src: "_DAG6196.jpg", title: "Landscape View 3" },
      { type: "image", src: "_DAG6597.jpg", title: "Landscape View 4" },
      { type: "image", src: "DJI_0237-HDR.jpg", title: "Aerial View 1" },
      { type: "image", src: "DJI_0544-HDR.jpg", title: "Aerial View 2" },
      { type: "image", src: "DJI_0777-HDR.jpg", title: "Aerial View 3" },
      { type: "image", src: "DJI_0958-HDR.jpg", title: "Aerial View 4" },
    ]
  },
  "Activities": {
    type: "folder",
    items: [
      { type: "image", src: "Concord River Tubing (1).jpg", title: "River Tubing View 1" },
      { type: "image", src: "Concord River Tubing (2).jpg", title: "River Tubing View 2" },
      { type: "image", src: "Concord River Tubing (3).jpg", title: "River Tubing View 3" },
      { type: "image", src: "Concord River Tubing (4).jpg", title: "River Tubing View 4" },
      { type: "image", src: "Concord River Tubing (5).jpg", title: "River Tubing View 5" },
      { type: "image", src: "Ti Kwen Glo Sho (1).jpg", title: "Ti Kwen Glo Sho View 1" },
      { type: "image", src: "Ti Kwen Glo Sho (2).jpg", title: "Ti Kwen Glo Sho View 2" },
      { type: "image", src: "Ti Kwen Glo Sho (3).jpg", title: "Ti Kwen Glo Sho View 3" },
      { type: "image", src: "Ti Kwen Glo Sho (4).jpg", title: "Ti Kwen Glo Sho View 4" },
      { type: "image", src: "Ti Kwen Glo Sho (5).jpg", title: "Ti Kwen Glo Sho View 5" },
      { type: "image", src: "Ti Kwen Glo Sho (6).jpg", title: "Ti Kwen Glo Sho View 6" },
      { type: "image", src: "GOPR0677.jpg", title: "Activity View" },
      { type: "image", src: "DSC_0414.jpeg", title: "Activity Scene 1" },
      { type: "image", src: "DSC_2278.jpeg", title: "Activity Scene 2" },
      { type: "image", src: "DSC_3432.jpeg", title: "Activity Scene 3" },
      { type: "image", src: "DSC_3521.jpeg", title: "Activity Scene 4" },
    ]
  },
  "Marine Life": {
    type: "folder",
    items: [
      { type: "image", src: "Leatherback.jpeg", title: "Leatherback Turtle" },
      { type: "image", src: "MAD_2084.jpeg", title: "Marine Scene 1" },
      { type: "image", src: "MAD_4736.jpeg", title: "Marine Scene 2" },
      { type: "image", src: "MAD_5256.jpeg", title: "Marine Scene 3" },
      { type: "image", src: "MAD_5304.jpeg", title: "Marine Scene 4" },
      { type: "image", src: "MAD_9811.jpeg", title: "Marine Scene 5" },
      { type: "image", src: "Madisetti Images Dominica_12.jpeg", title: "Marine Scene 6" },
      { type: "image", src: "Nose Reef_23.jpeg", title: "Nose Reef" },
      { type: "image", src: "Soufriere Pinnacles_04.jpeg", title: "Soufriere Pinnacles" },
      { type: "image", src: "Tube Reef_06.jpeg", title: "Tube Reef View 1" },
      { type: "image", src: "Tube Reef_20.jpeg", title: "Tube Reef View 2" },
    ]
  },
  "Videos": {
    type: "folder",
    items: [
      { type: "video", src: "Jazz 2024_UNITE.mp4", title: "Jazz 2024 UNITE" },
      { type: "video", src: "DDA Winter 1 - Epic.mp4", title: "DDA Winter 1 - Epic" },
      { type: "video", src: "DDA Winter 3 - Aqua.mp4", title: "DDA Winter 3 - Aqua" },
      { type: "video", src: "Caricom-WCMF Dominica Final.mp4", title: "Caricom-WCMF Dominica Final" },
      { type: "video", src: "CARICOM - Dominica Adventure Final.mp4", title: "CARICOM - Dominica Adventure Final" },
    ]
  }
};

const DominicaDestination = () => {
  const [activeFolder, setActiveFolder] = useState(null);
  const [activeMedia, setActiveMedia] = useState(null);
  
  // Handle folder click
  const openFolder = (folderName) => {
    setActiveFolder(folderName);
    setActiveMedia(null);
  };
  
  // Handle back button click
  const goBack = () => {
    if (activeMedia) {
      setActiveMedia(null);
    } else {
      setActiveFolder(null);
    }
  };
  
  // Handle media click
  const openMedia = (media) => {
    setActiveMedia(media);
  };
  
  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: { 
        staggerChildren: 0.1 
      }
    }
  };
  
  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: { 
      y: 0, 
      opacity: 1,
      transition: { duration: 0.5 }
    }
  };

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="min-h-screen bg-gradient-to-b from-[#006B3F] via-[#F4F4F4] to-[#F4F4F4] text-dark-brown"
    >
      {/* Hero Section */}
      <div className="relative h-[60vh] overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-r from-black via-transparent to-transparent"></div>
        <div className="absolute inset-0 bg-black opacity-40"></div>
        
        {/* Use one of the aerial images as hero */}
        <img 
          src={require('../assets/Dominica Destination/DJI_0777-HDR.jpg')} 
          alt="Dominica Nature Island" 
          className="w-full h-full object-cover object-center"
          loading="eager" 
        />
        
        <div className="absolute inset-0 flex items-center">
          <div className="container mx-auto px-4">
            <motion.div
              initial={{ y: -50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.8, delay: 0.2 }}
              className="max-w-4xl"
            >
              <h1 className="text-5xl md:text-7xl font-serif text-white leading-tight drop-shadow-lg mb-4">
                Dominica: <span className="text-[#FFD100]">The Nature Island</span>
              </h1>
              <p className="text-xl md:text-2xl text-white max-w-2xl drop-shadow-md">
                Discover the Caribbean's best-kept secret - a lush paradise of rainforests, waterfalls, and pristine beaches
              </p>
            </motion.div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="container mx-auto px-4 py-12">
        {/* Introduction */}
        <motion.div 
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="max-w-4xl mx-auto mb-16 text-center"
        >
          <h2 className="text-3xl md:text-4xl font-serif text-[#006B3F] mb-8">Experience Dominica</h2>
          <p className="text-lg md:text-xl mb-6 leading-relaxed">
            Known as "The Nature Island of the Caribbean," Dominica offers an unparalleled experience for nature lovers, adventure seekers, and those looking to reconnect with the natural world. With its pristine rainforests, breathtaking waterfalls, hot springs, and world-class diving spots, Dominica provides a perfect backdrop for executive retreats focused on sustainability, wellness, and authentic experiences.
          </p>
          <p className="text-lg md:text-xl leading-relaxed">
            Explore our curated collection of images and videos to discover the wonders of Dominica and start planning your next executive retreat in this magical destination.
          </p>
        </motion.div>

        {/* Media Browser Section */}
        <div className="bg-white rounded-xl shadow-xl p-8 mb-16">
          <div className="flex justify-between items-center mb-8">
            <h3 className="text-2xl md:text-3xl font-serif text-[#006B3F]">
              {activeFolder ? 
                <button 
                  onClick={goBack} 
                  className="flex items-center text-[#006B3F] hover:text-[#004B2F] transition-colors"
                >
                  <FaArrowLeft className="mr-2" /> {activeFolder}
                </button> 
                : "Explore Dominica"
              }
            </h3>
            {activeFolder && !activeMedia && (
              <p className="text-gray-600">{content[activeFolder].items.length} items</p>
            )}
          </div>

          <AnimatePresence mode="wait">
            {/* Folder View */}
            {!activeFolder && (
              <motion.div 
                variants={containerVariants}
                initial="hidden"
                animate="visible"
                exit="hidden"
                className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6"
              >
                {Object.keys(content).map((folderName) => (
                  <motion.div
                    key={folderName}
                    variants={itemVariants}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={() => openFolder(folderName)}
                    className="bg-gray-100 rounded-lg p-6 flex flex-col items-center justify-center cursor-pointer shadow-md hover:shadow-lg transition-all"
                  >
                    <div className="bg-[#006B3F] text-white p-4 rounded-full mb-4">
                      <FaFolderOpen size={32} />
                    </div>
                    <h4 className="text-lg font-bold text-center">{folderName}</h4>
                    <p className="text-gray-600 text-sm">{content[folderName].items.length} items</p>
                  </motion.div>
                ))}
              </motion.div>
            )}

            {/* Media Grid View */}
            {activeFolder && !activeMedia && (
              <motion.div 
                variants={containerVariants}
                initial="hidden"
                animate="visible"
                exit="hidden"
                className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6"
              >
                {content[activeFolder].items.map((item, index) => (
                  <motion.div
                    key={index}
                    variants={itemVariants}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={() => openMedia(item)}
                    className="bg-gray-100 rounded-lg overflow-hidden shadow-md hover:shadow-lg transition-all cursor-pointer group"
                  >
                    <div className="relative aspect-w-3 aspect-h-2 bg-gray-200">
                      {item.type === "image" ? (
                        <div className="h-48 overflow-hidden">
                          <img 
                            src={require(`../assets/Dominica Destination/${item.src}`)} 
                            alt={item.title}
                            className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-500"
                            loading="lazy"
                          />
                        </div>
                      ) : (
                        <div className="h-48 bg-gray-900 flex items-center justify-center">
                          <FaVideo className="text-white opacity-70 text-4xl" />
                        </div>
                      )}
                      <div className="absolute inset-0 bg-black bg-opacity-20 group-hover:bg-opacity-10 transition-all flex items-center justify-center">
                        <div className="p-2 rounded-full bg-white bg-opacity-0 group-hover:bg-opacity-70 transition-all">
                          {item.type === "image" ? <FaImage className="text-transparent group-hover:text-[#006B3F]" /> : <FaVideo className="text-transparent group-hover:text-[#006B3F]" />}
                        </div>
                      </div>
                    </div>
                    <div className="p-4">
                      <h5 className="font-medium text-sm truncate">{item.title}</h5>
                      <p className="text-xs text-gray-500 capitalize">{item.type}</p>
                    </div>
                  </motion.div>
                ))}
              </motion.div>
            )}

            {/* Media View */}
            {activeMedia && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="bg-gray-100 rounded-lg p-4 md:p-6"
              >
                <div className="mb-4 md:mb-6">
                  <h4 className="text-lg md:text-xl font-bold mb-2">{activeMedia.title}</h4>
                  <p className="text-gray-600 capitalize">{activeMedia.type}</p>
                </div>
                
                <div className="max-w-4xl mx-auto">
                  {activeMedia.type === "image" ? (
                    <img 
                      src={require(`../assets/Dominica Destination/${activeMedia.src}`)} 
                      alt={activeMedia.title}
                      className="w-full h-auto max-h-[70vh] object-contain rounded-lg shadow-md"
                      loading="lazy"
                    />
                  ) : (
                    <div className="relative pt-[56.25%]">
                      <video 
                        controls 
                        className="absolute inset-0 w-full h-full rounded-lg shadow-md"
                      >
                        <source src={require(`../assets/Dominica Destination/${activeMedia.src}`)} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  )}
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>

        {/* Features Section */}
        <motion.div
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="max-w-6xl mx-auto mb-16"
        >
          <h2 className="text-3xl md:text-4xl font-serif text-[#006B3F] mb-8 text-center">Why Choose Dominica</h2>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-8">
            <div className="bg-white rounded-xl shadow-md p-5 md:p-6 border-t-4 border-[#006B3F]">
              <h3 className="text-xl font-bold mb-3 md:mb-4 text-[#006B3F]">Natural Wonders</h3>
              <p className="text-sm md:text-base text-gray-700">
                Experience the breathtaking beauty of Dominica's natural wonders, from the world's second-largest boiling lake to pristine waterfalls and lush rainforests that cover 60% of the island.
              </p>
            </div>
            
            <div className="bg-white rounded-xl shadow-md p-5 md:p-6 border-t-4 border-[#FFD100]">
              <h3 className="text-xl font-bold mb-3 md:mb-4 text-[#006B3F]">Wellness & Rejuvenation</h3>
              <p className="text-sm md:text-base text-gray-700">
                Dominica's natural hot springs, mineral baths, and pristine environment make it an ideal destination for wellness retreats focused on rejuvenation and mindfulness.
              </p>
            </div>
            
            <div className="bg-white rounded-xl shadow-md p-5 md:p-6 border-t-4 border-[#006B3F]">
              <h3 className="text-xl font-bold mb-3 md:mb-4 text-[#006B3F]">Sustainable Luxury</h3>
              <p className="text-sm md:text-base text-gray-700">
                Enjoy world-class eco-luxury resorts that combine comfort with sustainability, providing the perfect setting for environmentally conscious executive retreats.
              </p>
            </div>
          </div>
        </motion.div>

        {/* Call to Action */}
        <motion.div
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="bg-[#006B3F] text-white rounded-xl shadow-xl p-6 md:p-12 text-center max-w-4xl mx-auto"
        >
          <h2 className="text-2xl md:text-3xl lg:text-4xl font-serif mb-4 md:mb-6">Ready to Experience Dominica?</h2>
          <p className="text-base md:text-lg lg:text-xl mb-6 md:mb-8 max-w-2xl mx-auto">
            Let us help you plan an unforgettable executive retreat on the Nature Island. Our team will create a customized experience that combines professional development with the natural wonders of Dominica.
          </p>
          <button className="bg-[#FFD100] text-[#006B3F] px-6 md:px-8 py-2 md:py-3 rounded-full text-base md:text-lg font-bold hover:bg-[#FFE047] transition-colors shadow-lg">
            Plan Your Retreat
          </button>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default DominicaDestination; 